import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
// import ShareEvent from "@bgea/gatsby-components-common/dist/components/Share.js";
import { SEO } from '../components/seo';
import { graphql } from 'gatsby';

function Page({ pageContext }) {
  const page = pageContext;

  return (
    <Layout>
      {/* <ShareEvent items={["Facebook", "Twitter", "URL"]} /> */}
      <div>
        <div className="page-banner has-text-centered py-6">
          <h1 className="title m-2">{page.frontmatter.title}</h1>
        </div>
        <div className="content container p-5">
          <a
            href="/wydarzenia/"
            className="has-text-weight-semibold is-size-6 link"
          >
            {' '}
            &#60;&#60; Wszystkie wydarzenia
          </a>
          <h4 className="mt-6">
            {page.frontmatter.date2} &#64; {page.frontmatter.start_time}
          </h4>
          <hr />
          <h5>Miejsce: {page.frontmatter.venue_name}</h5>
          <h5>
            Adres:
            <br />
            {page.frontmatter.venue_address}
          </h5>
          <div>
            <MDXRenderer>{page.body}</MDXRenderer>
            <div className="map-responsive">
              <iframe
                src={page.frontmatter.map_link}
                width="600"
                height="450"
                allowfullscreen=""
                loading="lazy"
                title={page.frontmatter.title}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Page;

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
      }
      body
    }
  }
`;

export const Head = ({
  data: {
    mdx: {
      frontmatter: { title },
    },
  },
}) => {
  return <SEO title={title} />;
};
